import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { SparkWebLocationExperienceDetailsDto } from '../../../../../libs/contracts/src/lib/spark/tenant/v1/experience/experience.dto';
import { formatEnum } from 'src/utils/change-case';

export default function ExperienceDetails({
  experience,
}: {
  experience: SparkWebLocationExperienceDetailsDto;
}) {
  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h3">{experience.name}</Typography>

        <Typography variant="body1" color="text.secondary">
          {experience.description || 'No description available'}
        </Typography>
      </Stack>
    </Stack>
  );
}
