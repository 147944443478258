import { endpoints, webAxiosInstance } from 'src/utils/axios';

import { setSession } from './utils';
import { SparkWebSendOTPDto } from '../../../../../../libs/contracts/src/lib/spark/tenant/v1/auth/web/send-otp.dto';
import { SparkWebVerifyOTPDto } from '../../../../../../libs/contracts/src/lib/spark/tenant/v1/auth/web/verify-otp.dto';
import { SparkWebCompleteProfileDto } from '../../../../../../libs/contracts/src/lib/spark/tenant/v1/auth/web/complete-profile.dto';

// ----------------------------------------------------------------------

export type SendOtpParams = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

/** **************************************
 * Complete Profile
 *************************************** */
export const completeProfile = async (
  data: SparkWebCompleteProfileDto,
): Promise<void> => {
  try {
    const res = await webAxiosInstance.post(
      endpoints.web.auth.completeProfile,
      data,
    );
    return res.data.data;
  } catch (error) {
    throw error;
  }
};
/** **************************************
 * Send Otp
 *************************************** */
export const sendOtp = async ({
  phoneNumber,
}: SparkWebSendOTPDto): Promise<void> => {
  try {
    const params = { phoneNumber };
    await webAxiosInstance.post(endpoints.web.auth.sendOtp, params);
  } catch (error) {
    throw error;
  }
};
/** **************************************
 * Verify Otp
 *************************************** */
export const verifyOtp = async ({
  phoneNumber,
  code,
}: SparkWebVerifyOTPDto): Promise<void> => {
  try {
    const params = { phoneNumber, code };
    const res = await webAxiosInstance.post(
      endpoints.web.auth.verifyOtp,
      params,
    );

    const { token } = res.data.data;

    if (!token) {
      throw new Error('Access token not found in response');
    }

    setSession(token);
  } catch (error) {
    throw error;
  }
};
/** **************************************
 * Sign Out
 *************************************** */
export const signOut = async (): Promise<void> => {
  try {
    await setSession(null);
  } catch (error) {
    throw error;
  }
};
