import { Box, Container, Grid } from '@mui/material';
import { MainContent } from 'src/layouts/main';
import { SparkWebLocationExperienceDetailsDto } from '../../../../../../libs/contracts/src/lib/spark/tenant/v1/experience/experience.dto';
import ExperienceDatePicker from '../experience-date-picker';
import ExperienceSlotsPicker from '../experience-slots-picker';
import ExperienceTickets from '../experience-tickets';
import ExperienceBookingNavBar from '../experience-booking-nav-bar';
import ExperienceGallery from '../experience-gallery';
import ExperienceDetails from '../experience-details';

type Props = {
  experience: SparkWebLocationExperienceDetailsDto;
};

export default function ExperienceDetailsView({ experience }: Props) {
  return (
    <MainContent>
      <ExperienceGallery media={experience.media} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ExperienceDetails experience={experience} />
          </Grid>

          <Grid item xs={12} md={6}>
            <ExperienceDatePicker />
            <ExperienceSlotsPicker experience={experience} />
            <ExperienceTickets tickets={experience.tickets} />
          </Grid>
        </Grid>

        {/* Add padding to prevent content from being hidden behind the bottom bar */}
        <Box sx={{ height: 80 }} />
      </Container>

      <ExperienceBookingNavBar experienceTickets={experience.tickets} />
    </MainContent>
  );
}
