import { useContext } from 'react';

import { UserAuthContext } from '../context/user-auth-context';

// ----------------------------------------------------------------------

export function useUserAuthContext() {
  const context = useContext(UserAuthContext);

  if (!context) {
    throw new Error(
      'useUserAuthContext: Context must be used inside UserAuthContext',
    );
  }

  return context;
}
