import { endpoints, webAxiosInstance, reValidate } from 'src/utils/axios';
import { SparkWebBookSlotDto } from '../../../../../libs/contracts/src/lib/spark/tenant/v1/booking/web/book-slot.dto';

export async function bookSlot(data: SparkWebBookSlotDto) {
  const URL = endpoints.web.bookings.book;

  await webAxiosInstance.post(URL, data);
  reValidate(endpoints.web.locations.key);
}

export async function cancelBooking(id: number) {
  const URL = endpoints.web.bookings.cancelBooking(id);

  await webAxiosInstance.patch(URL);
  reValidate(endpoints.web.locations.key);
}
