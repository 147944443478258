import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { SplashScreen } from 'src/components/loading-screen';

import { UserAuthLayout } from 'src/layouts/user-auth';
import { UserAuthGuard, UserGuestGuard } from 'src/user-auth/guard';

// ----------------------------------------------------------------------

/** **************************************
 * Jwt
 *************************************** */
const Jwt = {
  SignInPage: lazy(() => import('src/pages/user-auth/sign-in')),
  CompleteProfilePage: lazy(
    () => import('src/pages/user-auth/complete-profile'),
  ),
};

const authJwt = [
  {
    path: 'sign-in',
    element: (
      <UserGuestGuard>
        <UserAuthLayout>
          <Jwt.SignInPage />
        </UserAuthLayout>
      </UserGuestGuard>
    ),
  },
  {
    path: 'complete-profile',
    element: (
      <UserAuthGuard>
        <UserAuthLayout>
          <Jwt.CompleteProfilePage />
        </UserAuthLayout>
      </UserAuthGuard>
    ),
  },
];
// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [...authJwt],
  },
];
