import { Button, Container, Tooltip } from '@mui/material';

import { Box, Stack, Typography } from '@mui/material';
import { Iconify } from 'src/components/iconify';
import { useCheckoutContext } from '../checkout/context/use-checkout-context';
import { SparkTicketDto } from '../../../../../libs/contracts/src/lib/spark/tenant/v1/ticket/ticket.dto';
import { bookSlot } from 'src/api/web/booking';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
import { usePathname, useRouter } from 'src/routes/hooks';
import { useUserAuthContext } from 'src/user-auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import RegisterLoginDialog from 'src/components/register-dialog/register-login-dialog';
import { SpacePricingEnum } from '../../../../../libs/space/src/lib/enums/space-pricing.enum';
import { useResponsive } from 'src/hooks/use-responsive';

export default function ExperienceBookingNavBar({
  experienceTickets,
}: {
  experienceTickets: SparkTicketDto[];
}) {
  const dialog = useBoolean();
  const router = useRouter();
  const { slots, tickets, calculateTicketsBookingTotalPrice, space } =
    useCheckoutContext();
  const [isLoading, setIsLoading] = useState(false);
  const { authenticated } = useUserAuthContext();
  const pathname = usePathname();

  const disableBooking = Boolean(!space?.enableBookingFromWebsite);
  const mdDown = useResponsive('down', 'md');
  const isNeedTicket = space?.pricingType === SpacePricingEnum.PER_PERSON;
  const isEmptySlots = slots.length === 0;
  const isEmptyTickets = tickets.length === 0;
  const isDisabled =
    isEmptySlots || (isNeedTicket && isEmptyTickets) || disableBooking;

  const handleBookNow = async () => {
    if (!authenticated) {
      dialog.onTrue();
      return;
    }
    if (isDisabled) return;
    try {
      setIsLoading(true);
      await bookSlot({
        slotIds: slots.map((slot) => slot.id) as [string],
        tickets: isNeedTicket ? tickets : undefined,
      });
      toast.success('Booking successful');
      router.push(paths.tenant.booking.success);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          py: 2,
          px: 3,
          bgcolor: 'background.paper',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          borderTop: '1px solid',
          borderColor: 'divider',
          boxShadow: (theme) =>
            `0 -4px 12px ${theme.palette.mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0.3)'}`,
          zIndex: 1200,
        }}
      >
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={0.5}>
              <Typography variant="caption" color="text.secondary">
                Total Price
              </Typography>
              <Typography variant="h6" color="primary.main">
                {calculateTicketsBookingTotalPrice(experienceTickets)} EGP
              </Typography>
            </Stack>
            <Tooltip
              placement="top-end"
              arrow
              title={
                disableBooking
                  ? 'This space is currently not bookable. Please contact the administrator for further assistance.'
                  : ''
              }
              open={mdDown ? disableBooking : undefined}
            >
              <span>
                <LoadingButton
                  variant="contained"
                  size="large"
                  disabled={isDisabled}
                  startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}
                  loading={isLoading}
                  onClick={handleBookNow}
                >
                  Book Now
                </LoadingButton>
              </span>
            </Tooltip>
          </Stack>
        </Container>
      </Box>
      <RegisterLoginDialog
        open={dialog.value}
        onClose={dialog.onFalse}
        onRegister={() => {
          router.push(`${paths.userAuth.signIn}?returnTo=${pathname}`);
        }}
        onLogin={() => {
          router.push(`${paths.userAuth.signIn}?returnTo=${pathname}`);
        }}
      />
    </>
  );
}
