import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { CONFIG } from 'src/config-global';
import { DashboardLayout } from 'src/layouts/dashboard';
import { AuthSplitLayout } from 'src/layouts/auth-split';

import { LoadingScreen } from 'src/components/loading-screen';
import { SplashScreen } from 'src/components/loading-screen';

import { AuthGuard, PermissionGuard, GuestGuard } from 'src/auth/guard';
import {
  AdminPermission,
  BookingPermission,
  EventPermission,
  LocationPermission,
  RolePermission,
  SpacePermission,
  TransactionPermission,
} from '../../../../../libs/role/src/lib/types/permission-type.type';

// Authentication
/** **************************************
 * Jwt
 *************************************** */
const Jwt = {
  SignInPage: lazy(() => import('src/pages/auth/sign-in')),
  ForgetPasswordPage: lazy(() => import('src/pages/auth/forget-password')),
  ResetPasswordPage: lazy(() => import('src/pages/auth/reset-password')),
};

// ----------------------------------------------------------------------
// Profile
const ProfilePage = lazy(() => import('src/pages/dashboard/profile'));

// Locations
const LocationListPage = lazy(
  () => import('src/pages/dashboard/location/list'),
);
const LocationCreatePage = lazy(
  () => import('src/pages/dashboard/location/new'),
);
const LocationEditPage = lazy(
  () => import('src/pages/dashboard/location/edit'),
);
const LocationViewPage = lazy(
  () => import('src/pages/dashboard/location/view'),
);

// Space
const SpaceListPage = lazy(() => import('src/pages/dashboard/space/list'));
const SpaceCreatePage = lazy(() => import('src/pages/dashboard/space/new'));
const SpaceEditPage = lazy(() => import('src/pages/dashboard/space/edit'));
const SpaceViewPage = lazy(() => import('src/pages/dashboard/space/view'));

// Bookings
const BookingsListPage = lazy(
  () => import('src/pages/dashboard/bookings/list'),
);
const BookingsCalendarPage = lazy(
  () => import('src/pages/dashboard/bookings/calendar'),
);

// Transactions
const TransactionsListPage = lazy(
  () => import('src/pages/dashboard/transactions/list'),
);

// Events
const EventListPage = lazy(() => import('src/pages/dashboard/event/list'));
const EventCreatePage = lazy(() => import('src/pages/dashboard/event/new'));
const EventViewPage = lazy(() => import('src/pages/dashboard/event/view'));

// Classes
const ClassCalendarPage = lazy(
  () => import('src/pages/dashboard/class/calendar'),
);
const ClassCreatePage = lazy(() => import('src/pages/dashboard/class/new'));
const ClassDetailsPage = lazy(() => import('src/pages/dashboard/class/view'));
const ClassEditPage = lazy(() => import('src/pages/dashboard/class/edit'));
const ClassSessionEditPage = lazy(
  () => import('src/pages/dashboard/class/edit-session'),
);
const ClassListPage = lazy(() => import('src/pages/dashboard/class/list'));

// Customers
const CustomersListPage = lazy(
  () => import('src/pages/dashboard/customers/list'),
);
const CustomersDetailsPage = lazy(
  () => import('src/pages/dashboard/customers/view'),
);

// Gallery
// Admins
const AdminsListPage = lazy(() => import('src/pages/dashboard/admins/list'));
const AdminsCreatePage = lazy(() => import('src/pages/dashboard/admins/new'));
const AdminsEditPage = lazy(() => import('src/pages/dashboard/admins/edit'));

// Roles
const RolesListPage = lazy(() => import('src/pages/dashboard/roles/list'));
const RolesCreatePage = lazy(() => import('src/pages/dashboard/roles/new'));
const RolesEditPage = lazy(() => import('src/pages/dashboard/roles/edit'));

// Settings
const GalleryViewPage = lazy(() => import('src/pages/dashboard/gallery/view'));
const ContactViewPage = lazy(() => import('src/pages/dashboard/contacts/view'));
// Error
const Page403 = lazy(() => import('src/pages/error/403'));
const Page404 = lazy(() => import('src/pages/error/404'));

// ----------------------------------------------------------------------

const layoutContent = (
  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  </DashboardLayout>
);

export const dashboardRoutes = [
  {
    path: '/dashboard/auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'sign-in',
        element: (
          <GuestGuard>
            <AuthSplitLayout>
              <Jwt.SignInPage />
            </AuthSplitLayout>
          </GuestGuard>
        ),
        index: true,
      },
      {
        path: 'forget-password',
        element: (
          <GuestGuard>
            <AuthSplitLayout>
              <Jwt.ForgetPasswordPage />
            </AuthSplitLayout>
          </GuestGuard>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <GuestGuard>
            <AuthSplitLayout>
              <Jwt.ResetPasswordPage />
            </AuthSplitLayout>
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: '/dashboard',
    element: CONFIG.auth.skip ? (
      layoutContent
    ) : (
      <AuthGuard>{layoutContent}</AuthGuard>
    ),
    children: [
      {
        path: '',
        children: [
          {
            element: (
              <PermissionGuard
                requiredPermission={LocationPermission.list}
                isPage
              >
                <LocationListPage />
              </PermissionGuard>
            ),
            index: true,
          },
          { path: '403', element: <Page403 /> },
          { path: '404', element: <Page404 /> },
        ],
      },
      {
        path: 'profile',
        children: [{ element: <ProfilePage />, index: true }],
      },
      {
        path: 'locations',
        children: [
          {
            element: (
              <PermissionGuard
                requiredPermission={LocationPermission.list}
                isPage
              >
                <LocationListPage />{' '}
              </PermissionGuard>
            ),
            index: true,
          },
          {
            path: ':id',
            element: (
              <PermissionGuard
                requiredPermission={LocationPermission.view}
                isPage
              >
                <LocationViewPage />
              </PermissionGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <PermissionGuard
                requiredPermission={LocationPermission.add}
                isPage
              >
                <LocationCreatePage />
              </PermissionGuard>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <PermissionGuard
                requiredPermission={LocationPermission.edit}
                isPage
              >
                <LocationEditPage />
              </PermissionGuard>
            ),
          },
          {
            path: ':id/spaces/new',
            element: (
              <PermissionGuard requiredPermission={SpacePermission.add} isPage>
                <SpaceCreatePage />
              </PermissionGuard>
            ),
          },
          {
            path: ':id/spaces/:spaceId',
            element: (
              <PermissionGuard requiredPermission={SpacePermission.edit} isPage>
                <SpaceViewPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'spaces',
        children: [
          { element: <SpaceListPage />, index: true },
          { path: ':id', element: <SpaceViewPage /> },
          { path: 'new', element: <SpaceCreatePage /> },
          { path: ':id/edit', element: <SpaceEditPage /> },
        ],
      },
      {
        path: 'bookings',
        children: [
          {
            path: 'list',
            element: (
              <PermissionGuard
                requiredPermission={BookingPermission.list}
                isPage
              >
                <BookingsListPage />
              </PermissionGuard>
            ),
            index: true,
          },
          {
            path: 'calendar',
            element: (
              <PermissionGuard
                requiredPermission={BookingPermission.calender}
                isPage
              >
                <BookingsCalendarPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'transactions',
        children: [
          {
            element: (
              <PermissionGuard
                requiredPermission={TransactionPermission.list}
                isPage
              >
                <TransactionsListPage />
              </PermissionGuard>
            ),
            index: true,
          },
        ],
      },
      {
        path: 'events',
        children: [
          {
            element: (
              <PermissionGuard requiredPermission={EventPermission.list} isPage>
                <EventListPage />
              </PermissionGuard>
            ),
            index: true,
          },
          {
            path: 'new',
            element: (
              <PermissionGuard requiredPermission={EventPermission.add} isPage>
                <EventCreatePage />
              </PermissionGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <PermissionGuard requiredPermission={EventPermission.view} isPage>
                <EventViewPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'admins',
        children: [
          {
            element: (
              <PermissionGuard requiredPermission={AdminPermission.list} isPage>
                <AdminsListPage />
              </PermissionGuard>
            ),
            index: true,
          },
          {
            path: 'new',
            element: (
              <PermissionGuard requiredPermission={AdminPermission.add} isPage>
                <AdminsCreatePage />
              </PermissionGuard>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <PermissionGuard requiredPermission={AdminPermission.edit} isPage>
                <AdminsEditPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'roles',
        children: [
          {
            element: (
              <PermissionGuard requiredPermission={RolePermission.list} isPage>
                <RolesListPage />
              </PermissionGuard>
            ),
            index: true,
          },
          {
            path: 'new',
            element: (
              <PermissionGuard requiredPermission={RolePermission.add} isPage>
                <RolesCreatePage />
              </PermissionGuard>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <PermissionGuard requiredPermission={RolePermission.edit} isPage>
                <RolesEditPage />
              </PermissionGuard>
            ),
          },
        ],
      },
      {
        path: 'classes',
        children: [
          { path: 'calendar', element: <ClassCalendarPage /> },
          { path: 'list', element: <ClassListPage /> },
          { path: 'new', element: <ClassCreatePage /> },
          { path: ':classId/edit', element: <ClassEditPage /> },
          {
            path: ':classId/sessions/:sessionId/edit',
            element: <ClassSessionEditPage />,
          },
          {
            path: ':classId/sessions/:sessionId',
            element: <ClassDetailsPage />,
          },
        ],
      },
      {
        path: 'customers',
        children: [
          { element: <CustomersListPage />, index: true },
          { path: ':id/view', element: <CustomersDetailsPage /> },
        ],
      },
      {
        path: 'settings',
        children: [
          { path: 'gallery', element: <GalleryViewPage />, index: true },
          { path: 'contacts', element: <ContactViewPage /> },
        ],
      },
    ],
  },
];
