import 'dayjs/locale/en';
import 'dayjs/locale/ar-sa';

import dayjs from 'dayjs';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider as Provider } from '@mui/x-date-pickers/LocalizationProvider';

import { useTranslate } from './use-locales';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function LocalizationProvider({ children }: Props) {
  const { currentLang } = useTranslate();

  dayjs.locale(currentLang.adapterLocale);

  console.log(currentLang.adapterLocale);
  return <Provider dateAdapter={AdapterDateFns}>{children}</Provider>;
}
