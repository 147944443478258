import { paths } from 'src/routes/paths';

import { Iconify } from 'src/components/iconify';
import {
  AdminPermission,
  BookingPermission,
  ClassPermission,
  LocationPermission,
  RolePermission,
  SpacePermission,
  TenantContactPermission,
  TenantGalleryPermission,
  TransactionPermission,
  UserPermission,
} from '../../../../libs/role/src/lib/types/permission-type.type';

// ----------------------------------------------------------------------

const iconify = (name: string) => <Iconify icon={name} />;

const ICONS = {
  locations: iconify('material-symbols:map-outline'),
  spaces: iconify('icon-park-twotone:arena'),
  table: iconify('material-symbols:table'),
  calendar: iconify('uil:calender'),
  payment: iconify('material-symbols:payments'),
  event: iconify('material-symbols:event'),
  class: iconify('akar-icons:schedule'),
  admin: iconify('material-symbols:admin-panel-settings-outline'),
  role: iconify('icon-park-twotone:permissions'),
  gallery: iconify('solar:gallery-wide-bold'),
  customer: iconify('mdi:users-outline'),
  contacts: iconify('mdi:contacts'),
};

// ----------------------------------------------------------------------

export const navData = [
  {
    subheader: 'locations',
    items: [
      {
        title: 'locations',
        path: paths.locations.root,
        icon: ICONS.locations,
        permission: LocationPermission.list,
      },
      {
        title: 'spaces',
        path: paths.spaces.root,
        icon: ICONS.spaces,
        permission: SpacePermission.list,
      },
    ],
  },
  {
    subheader: 'bookings',
    items: [
      {
        title: 'table',
        path: paths.bookings.root,
        icon: ICONS.table,
        permission: BookingPermission.list,
      },
      {
        title: 'calendar',
        path: paths.bookings.calendar,
        icon: ICONS.calendar,
        permission: BookingPermission.calender,
      },
    ],
  },
  {
    subheader: 'finance',
    items: [
      {
        title: 'transactions',
        path: paths.transactions.root,
        icon: ICONS.payment,
        permission: TransactionPermission.list,
      },
    ],
  },

  {
    subheader: 'classes',
    items: [
      {
        title: 'list',
        path: paths.classes.list,
        icon: ICONS.table,
        permission: ClassPermission.list,
      },
      {
        title: 'calendar',
        path: paths.classes.root,
        icon: ICONS.class,
        permission: ClassPermission.list,
      },
    ],
    //   // {
    //   //   title: 'Events',
    //   //   path: paths.events.root,
    //   //   icon: ICONS.event,
    //   //   permission: EventPermission.list,
    //   // },
    // ],
  },
  {
    subheader: 'customers',
    items: [
      {
        title: 'customers',
        path: paths.customers.root,
        icon: ICONS.customer,
        permission: UserPermission.list,
      },
    ],
  },
  {
    subheader: 'accessControlLevel',
    items: [
      {
        title: 'admins',
        path: paths.admins.root,
        icon: ICONS.admin,
        permission: AdminPermission.list,
      },
      {
        title: 'roles',
        path: paths.roles.root,
        icon: ICONS.role,
        permission: RolePermission.list,
      },
    ],
  },

  {
    subheader: 'settings',
    items: [
      {
        title: 'gallery',
        path: paths.settings.gallery,
        icon: ICONS.gallery,
        permission: TenantGalleryPermission.list,
      },
      {
        title: 'contacts',
        path: paths.settings.contacts,
        icon: ICONS.contacts,
        permission: TenantContactPermission.list,
      },
    ],
  },
];
