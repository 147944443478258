import { createContext } from 'react';

import type { AuthContextValue } from '../types';

// ----------------------------------------------------------------------

export const UserAuthContext = createContext<AuthContextValue | undefined>(
  undefined,
);

export const UserAuthConsumer = UserAuthContext.Consumer;
