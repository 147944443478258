import type { BoxProps } from '@mui/material/Box';

import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';

import { RouterLink } from 'src/routes/components';

import { logoClasses } from './classes';
import { CONFIG } from 'src/config-global';
import { useCurrentTenantContext } from 'src/current-tenant';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export type LogoProps = BoxProps & {
  href?: string;
  disableLink?: boolean;
};

export const SparkLogo = forwardRef<HTMLDivElement, LogoProps>(
  (
    {
      width = 40,
      height = 40,
      disableLink = false,
      className,
      href = paths.dashboard.root,
      sx,
      ...other
    },
    ref,
  ) => {
    const { tenant } = useCurrentTenantContext();

    const logo = (
      <Box
        alt="logo"
        sx={{
          borderRadius: '50%',
        }}
        component="img"
        src={`${CONFIG.site.basePath}/logo/logo-single-green.svg`}
        width={width}
        height={height}
      />
    );

    return (
      <NoSsr
        fallback={
          <Box
            width={width}
            height={height}
            className={logoClasses.root.concat(
              className ? ` ${className}` : '',
            )}
            sx={{
              flexShrink: 0,
              display: 'inline-flex',
              verticalAlign: 'middle',
              ...sx,
            }}
          />
        }
      >
        <Box
          ref={ref}
          component={RouterLink}
          href={href}
          width={width}
          height={height}
          className={logoClasses.root.concat(className ? ` ${className}` : '')}
          aria-label="logo"
          sx={{
            flexShrink: 0,
            display: 'inline-flex',
            verticalAlign: 'middle',
            ...(disableLink && { pointerEvents: 'none' }),
            ...sx,
          }}
          {...other}
        >
          {logo}
        </Box>
      </NoSsr>
    );
  },
);
