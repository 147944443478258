import { useContext } from 'react';

import { CurrentTenantContext } from './current-tenant-provider';

// ----------------------------------------------------------------------

export function useCurrentTenantContext() {
  const context = useContext(CurrentTenantContext);

  if (!context)
    throw new Error(
      'useCurrentTenant must be use inside CurrentTenantProvider',
    );

  return context;
}
