import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { SplashScreen } from 'src/components/loading-screen';
import { MainLayout } from 'src/layouts/main';
import BookingSuccessView from 'src/pages/main/booking/view';
import ExperienceViewPage from 'src/pages/main/experience/view';

// ----------------------------------------------------------------------

// Tenant Details Page
const TenantDetailsPage = lazy(() => import('src/pages/main/tenant/view'));

// Location Details Page
const LocationDetailsPage = lazy(() => import('src/pages/main/location/view'));

// Error
const Page404 = lazy(() => import('src/pages/error/404'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    path: '/',
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      {
        path: '',
        element: <TenantDetailsPage />,
        index: true,
      },
      {
        path: 'locations',
        children: [
          {
            path: ':id',
            element: <LocationDetailsPage />,
          },
        ],
      },
      {
        path: 'experiences',
        children: [
          {
            path: ':id',
            element: <ExperienceViewPage />,
          },
        ],
      },
      {
        path: 'booking/success',
        element: <BookingSuccessView />,
      },

      { path: '404', element: <Page404 /> },
    ],
  },
];
