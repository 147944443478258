import useSWR from 'swr';
import { endpoints, fetcher, reValidate, axiosInstance } from 'src/utils/axios';
import { useMemo } from 'react';
import { PageOptionsDto } from '../../../../libs/contracts/src/lib/common/pagination/page-option.dto';
import {
  INotificationsApiResponse,
  IUseNotificationsReturn,
} from 'src/types/notification';
import { SparkTenantAdminUpdateFcmTokenDto } from '../../../../libs/contracts/src';

// ----------------------------------------------------------------------

export function useGetNotifications(
  pageOptions: PageOptionsDto,
): IUseNotificationsReturn {
  const URL = [
    endpoints.notifications.list,
    {
      params: {
        page: pageOptions.page,
        limit: pageOptions.limit,
      },
    },
  ];

  const { data, isLoading, error, isValidating, mutate } =
    useSWR<INotificationsApiResponse>(URL, fetcher, {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    });

  const memoizedValue = useMemo(
    () => ({
      notifications: data?.data.data || [],
      notificationsLoading: isLoading,
      notificationsError: error,
      notificationsValidating: isValidating,
      notificationsEmpty: !isLoading && !data?.data.data.length,
      notificationsTotalRecords: data?.data.totalRecords || 0,
      unreadCount: data?.data.unreadCount || 0,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate],
  );

  return memoizedValue;
}
export async function markAllNotificationsAsRead() {
  const URL = endpoints.notifications.markAllAsRead;
  await axiosInstance.post(URL);
  reValidate(endpoints.notifications.reValidateKey);
}

export async function updateFcmToken(data: SparkTenantAdminUpdateFcmTokenDto) {
  const URL = endpoints.auth.updateFcmToken;
  await axiosInstance.patch(URL, data);
  reValidate(endpoints.notifications.reValidateKey);
}
