import { defaultFont } from 'src/theme/core/typography';

import type { SettingsState } from './types';
import { localStorageGetItem } from 'src/utils/storage-available';
import { fallbackLng } from 'src/locales';

// ----------------------------------------------------------------------

export const STORAGE_KEY = 'app-settings';
const lng = localStorageGetItem('i18nextLng', fallbackLng);

export const defaultSettings: SettingsState = {
  colorScheme: 'light',
  direction: lng === 'ar' ? 'rtl' : 'ltr',
  contrast: 'default',
  navLayout: 'vertical',
  primaryColor: 'default',
  navColor: 'integrate',
  compactLayout: false,
  fontFamily: defaultFont,
} as const;
