
import { paths } from 'src/routes/paths';
import { HeaderBase } from '../core/header-base';
import { useBoolean } from 'src/hooks/use-boolean';


export function TenantNotFoundLayout({ children }: { children: React.ReactNode }){
const mobileNavOpen = useBoolean();
    return (
        <div>
          <HeaderBase
            disableElevation
            layoutQuery="md"
            onOpenNav={mobileNavOpen.onTrue} 
            slotsDisplay={{
              signIn: false,
              account: false,
              userAccount: false,
              purchase: false,
              contacts: false,
              searchbar: false,
              workspaces: false,
              menuButton: false,
              localization: false,
              notifications: false,
            }}
            slots={{
              topArea: null,
            }}
            slotProps={{ container: { maxWidth: false } }}
          
          />
          <main>{children}</main>
        </div>
      );
    }

