import { Helmet } from 'react-helmet-async';
import { useGetExperience } from 'src/api/web/experience';
import { LoadingScreen } from 'src/components/loading-screen';
import { CONFIG } from 'src/config-global';
import { useParams } from 'src/routes/hooks';
import { CheckoutProvider } from 'src/sections/checkout/context';
import { NotFoundUserView } from 'src/sections/error';
import ExperienceDetailsView from 'src/sections/experiences/view/experience-details-view';

// ----------------------------------------------------------------------

export default function ExperienceViewPage() {
  const { id: experienceId } = useParams();
  const { experience, experienceLoading, experienceError } = useGetExperience(
    Number(experienceId),
  );

  const renderError = <NotFoundUserView />;
  if (experienceError) return renderError;

  if (experienceLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Helmet>
        <title> {`Experience view | ${CONFIG.site.name}`}</title>
      </Helmet>

      <CheckoutProvider>
        <ExperienceDetailsView experience={experience} />
      </CheckoutProvider>
    </>
  );
}
