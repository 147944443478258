import { Box } from '@mui/material';
import { Image } from 'src/components/image';
import { CONFIG } from 'src/config-global';

export default function ExperienceGallery({
  media,
}: {
  media: { link: string }[];
}) {
  const mainImage =
    !media || media.length === 0 ? CONFIG.noImage : media[0].link;
  const otherImages = media?.slice(1, 5) || [];

  return (
    <Box
      gap={1}
      display="grid"
      gridTemplateColumns={{
        xs: '1fr',
        sm: '1fr',
        md: otherImages.length ? '2fr 1fr' : '1fr',
      }}
      sx={{ mb: 3 }}
    >
      <Image
        src={mainImage}
        sx={{
          borderRadius: 2,
          height: 400,
          width: '100%',
          objectFit: 'cover',
        }}
      />

      {otherImages.length > 0 && (
        <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={1}>
          {otherImages.map((item, index) => (
            <Image
              key={index}
              src={item.link}
              sx={{
                borderRadius: 2,
                height: 196,
                width: '100%',
                objectFit: 'cover',
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
