import type { IconButtonProps } from '@mui/material/IconButton';

import { m } from 'framer-motion';
import { useState } from 'react';

// import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { useBoolean } from 'src/hooks/use-boolean';

import { Iconify } from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { Scrollbar } from 'src/components/scrollbar';

import { NotificationItem } from './notification-item';

import { usePagination } from 'src/hooks/use-pagination';
import {
  markAllNotificationsAsRead,
  useGetNotifications,
} from 'src/api/notification';
import { Pagination } from '@mui/material';
import { NotificationDto } from '../../../../../../libs/contracts/src/lib/mobile/v1/notification/notification.dto';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export type NotificationsDrawerProps = IconButtonProps;

export function NotificationsDrawer({
  sx,
  ...other
}: NotificationsDrawerProps) {
  const drawer = useBoolean();
  const [, setIsLoading] = useState(false);
  const rowsPerPage = 10;
  const [pageNumber, setPageNumber] = useState(1);

  const {
    notifications,
    notificationsLoading,
    mutate,
    notificationsTotalRecords,
    unreadCount,
  } = useGetNotifications({
    limit: rowsPerPage,
    page: pageNumber,
    skip: (pageNumber - 1) * rowsPerPage,
    take: rowsPerPage,
  });
  const notificationsArray: NotificationDto[] = notifications || [];
  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };
  const handleMarkAllAsRead = async () => {
    try {
      setIsLoading(true);
      await markAllNotificationsAsRead();
      await mutate();
    } catch (error) {
      console.error('Failed to mark all as read:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      <Tooltip title="Mark all as read">
        <IconButton color="primary" onClick={handleMarkAllAsRead}>
          <Iconify icon="eva:done-all-fill" />
        </IconButton>
      </Tooltip>

      <IconButton
        onClick={drawer.onFalse}
        sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
      >
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderList = (
    <>
      <Scrollbar>
        {notificationsLoading ? (
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography>Loading...</Typography>
          </Box>
        ) : (
          <Box component="ul">
            {notificationsArray?.map((notification) => (
              <Box
                component="li"
                key={notification.id}
                sx={{ display: 'flex' }}
              >
                <NotificationItem
                  notification={notification}
                  onMarkAsRead={() => mutate()}
                />
              </Box>
            ))}
          </Box>
        )}
      </Scrollbar>

      {!notificationsLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            borderTop: '1px solid',
            borderColor: 'divider',
            pt: 2,
            pb: 2,
          }}
        >
          <Pagination
            count={Math.ceil(notificationsTotalRecords / rowsPerPage)}
            page={pageNumber}
            onChange={handleChangePage}
          />
        </Box>
      )}
    </>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={drawer.onTrue}
        sx={sx}
        {...other}
      >
        <Badge badgeContent={unreadCount} color="error">
          <SvgIcon>
            <path
              fill="currentColor"
              d="M18.75 9v.704c0 .845.24 1.671.692 2.374l1.108 1.723c1.011 1.574.239 3.713-1.52 4.21a25.794 25.794 0 0 1-14.06 0c-1.759-.497-2.531-2.636-1.52-4.21l1.108-1.723a4.393 4.393 0 0 0 .693-2.374V9c0-3.866 3.022-7 6.749-7s6.75 3.134 6.75 7"
              opacity="0.5"
            />
            <path
              fill="currentColor"
              d="M12.75 6a.75.75 0 0 0-1.5 0v4a.75.75 0 0 0 1.5 0zM7.243 18.545a5.002 5.002 0 0 0 9.513 0c-3.145.59-6.367.59-9.513 0"
            />
          </SvgIcon>
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{ sx: { width: 1, maxWidth: 420 } }}
      >
        {renderHead}
        {renderList}
      </Drawer>
    </>
  );
}
