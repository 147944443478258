import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export type ConfigValue = {
  api: {
    customHeader: string;
  };
  site: {
    name: string;
    serverUrl: string;
    assetURL: string;
    basePath: string;
    version: string;
  };
  auth: {
    method: 'jwt';
    skip: boolean;
    redirectPath: string;
  };
  firebase: {
    appId: string;
    apiKey: string;
    projectId: string;
    authDomain: string;
    storageBucket: string;
    measurementId: string;
    messagingSenderId: string;
    vapidKey: string;
  };
  sentry: {
    url: string;
  };
  noImage: string;
};

// ----------------------------------------------------------------------

export const CONFIG: ConfigValue = {
  api: {
    customHeader: import.meta.env.VITE_CUSTOM_HEADER ?? '',
  },
  site: {
    name: 'Spark',
    serverUrl: import.meta.env.VITE_SERVER_URL ?? '',
    assetURL: import.meta.env.VITE_ASSET_URL ?? '',
    basePath: import.meta.env.VITE_BASE_PATH ?? '',
    version: '6.0.0',
  },
  /**
   * Auth
   * @method jwt
   */
  auth: {
    method: 'jwt',
    skip: false,
    redirectPath: paths.tenant.root,
  },

  /**
   * Firebase
   */
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY ?? '',
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ?? '',
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID ?? '',
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ?? '',
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID ?? '',
    appId: import.meta.env.VITE_FIREBASE_APPID ?? '',
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID ?? '',
    vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY ?? '',
  },
  sentry: {
    url: import.meta.env.VITE_SENTRY_URL ?? '',
  },
  noImage:
    'https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image-768x576.png',
};
