import { endpoints, axiosInstance } from 'src/utils/axios';

import { setSession } from './utils';
import {
  SparkAdminAuthEmailLoginDto,
  SparkAdminAuthForgotPasswordDto,
  SparkAdminAuthResetPasswordDto,
  SparkAdminAuthChangePasswordDto,
} from '../../../../../../libs/contracts/src';

// ----------------------------------------------------------------------

export type SignUpParams = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

/** **************************************
 * Sign in
 *************************************** */
export const signInWithPassword = async ({
  email,
  password,
}: SparkAdminAuthEmailLoginDto): Promise<void> => {
  try {
    const params = { email, password };

    const res = await axiosInstance.post(endpoints.auth.login, params);

    const { token } = res.data.data;

    if (!token) {
      throw new Error('Access token not found in response');
    }

    setSession(token);
    // TODO: Uncomment this line to enable notification permission
    // requestNotificationPermission();
  } catch (error) {
    console.error('Error during sign in:', error);
    throw error;
  }
};

/** **************************************
 * Sign out
 *************************************** */
export const signOut = async (): Promise<void> => {
  try {
    await axiosInstance.post(endpoints.auth.logout);
    await setSession(null);
  } catch (error) {
    console.error('Error during sign out:', error);
    throw error;
  }
};

/** **************************************
 * Forget password
 *************************************** */
export const forgetPassword = async ({
  email,
}: SparkAdminAuthForgotPasswordDto): Promise<void> => {
  try {
    const params = { email };
    await axiosInstance.post(endpoints.auth.forgetPassword, params);
  } catch (error) {
    console.error('Error during forgetting password:', error);
    throw error;
  }
};

/** **************************************
 * Reset password
 *************************************** */
export const resetPassword = async ({
  email,
  password,
  code,
}: SparkAdminAuthResetPasswordDto): Promise<void> => {
  try {
    const params = { email, password, code };

    await axiosInstance.post(endpoints.auth.resetPassword, params);
  } catch (error) {
    console.error('Error during ressetting password:', error);
    throw error;
  }
};

/** **************************************
 * Change password
 *************************************** */
export const changePassword = async ({
  oldPassword,
  password,
}: SparkAdminAuthChangePasswordDto): Promise<void> => {
  try {
    const params = { oldPassword, password };

    await axiosInstance.patch(endpoints.auth.changePassword, params);
  } catch (error) {
    console.error('Error during changing password:', error);
    throw error;
  }
};
