export enum AdminPermission {
  add = 'admin.add',
  edit = 'admin.edit',
  list = 'admin.list',
  view = 'admin.view',
  delete = 'admin.delete',
}

export enum RolePermission {
  add = 'role.add',
  edit = 'role.edit',
  list = 'role.list',
  view = 'role.view',
  delete = 'role.delete',
}

export enum LocationPermission {
  add = 'location.add',
  edit = 'location.edit',
  list = 'location.list',
  view = 'location.view',
  delete = 'location.delete',
  reorder = 'location.reorder',
}

export enum SpacePermission {
  add = 'space.add',
  edit = 'space.edit',
  list = 'space.list',
  delete = 'space.delete',
  pricing = 'space.pricing',
  gallery = 'space.gallery',
  reorder = 'space.reorder',
}

export enum EventPermission {
  add = 'event.add',
  edit = 'event.edit',
  list = 'event.list',
  view = 'event.view',
  delete = 'event.delete',
}

export enum ClassPermission {
  add = 'class.add',
  edit = 'class.edit',
  list = 'class.list',
  view = 'class.view',
  delete = 'class.delete',
  gallery = 'class.gallery',
  logs = 'class.logs',
}

export enum DashboardPermission {
  view = 'dashboard.view',
  allDaysAccess = 'dashboard.allDaysAccess',
}

export enum BookingPermission {
  add = 'booking.add',
  import = 'booking.import',
  edit = 'booking.edit',
  list = 'booking.list',
  view = 'booking.view',
  calender = 'booking.calender',
  allDaysAccess = 'booking.allDaysAccess',
  cancel = 'booking.cancel',
  delete = 'booking.delete',
  complete = 'booking.complete',
  markAsMissed = 'booking.markAsMissed',
  markAsContacted = 'booking.markAsContacted',
  logs = 'booking.logs',
  statistics = 'booking.statistics',
}

export enum TransactionPermission {
  list = 'transaction.list',
  allDaysAccess = 'transaction.allDaysAccess',
  export = 'transaction.export',
}

export enum UserPermission {
  add = 'user.add',
  list = 'user.list',
  view = 'user.view',
  edit = 'user.edit',
  toggle = 'user.toggle',
}

export enum BannerPermission {
  add = 'banner.add',
  list = 'banner.list',
  view = 'banner.view',
  edit = 'banner.edit',
  delete = 'banner.delete',
}

export enum PackagePermission {
  add = 'package.add',
  list = 'package.list',
  view = 'package.view',
  edit = 'package.edit',
  delete = 'package.delete',
}

export enum TenantPermission {
  add = 'tenant.add',
  list = 'tenant.list',
  view = 'tenant.view',
  assignTenantToPackage = 'tenant.assignTenantToPackage',
  edit = 'tenant.edit',
  delete = 'tenant.delete',
}

export enum ApiSettingPermission {
  view = 'api_setting.view',
  edit = 'api_setting.edit',
}

export enum AuditTrailPermission {
  list = 'audit_trail.list',
}

export enum CampaignPermission {
  add = 'campaign.add',
  list = 'campaign.list',
  delete = 'campaign.delete',
}

export enum TenantGalleryPermission {
  list = 'tenant_gallery.list',
  add = 'tenant_gallery.add',
  edit = 'tenant_gallery.edit',
  delete = 'tenant_gallery.delete',
}

export enum TenantContactPermission {
  list = 'tenant_contact.list',
  add = 'tenant_contact.add',
  edit = 'tenant_contact.edit',
  delete = 'tenant_contact.delete',
}

export enum TicketPermission {
  add = 'ticket.add',
  edit = 'ticket.edit',
  list = 'ticket.list',
  delete = 'ticket.delete',
  reorder = 'ticket.reorder',
}

export enum SmsCampaignPermission {
  add = 'sms_campaign.add',
  list = 'sms_campaign.list',
  delete = 'sms_campaign.delete',
  view = 'sms_campaign.view',
}

export type PermissionType =
  | AdminPermission
  | RolePermission
  | LocationPermission
  | SpacePermission
  | DashboardPermission
  | BookingPermission
  | TransactionPermission
  | UserPermission
  | BannerPermission
  | PackagePermission
  | TenantPermission
  | EventPermission
  | ClassPermission
  | ApiSettingPermission
  | CampaignPermission
  | AuditTrailPermission
  | TenantGalleryPermission
  | TenantContactPermission
  | TicketPermission
  | SmsCampaignPermission;
