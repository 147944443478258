import { Navigate, useRoutes } from 'react-router-dom';

import { CONFIG } from 'src/config-global';

import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { dashboardRoutes } from './dashboard';
import { TenantNotFoundLayout } from 'src/layouts/main';
import { SplashScreen } from 'src/components/loading-screen';
import { lazy, Suspense } from 'react';

// ----------------------------------------------------------------------

// Error
const TenantNotFoundPage = lazy(() => import('src/pages/error/tenant-not-found'));

export function Router() {
  return useRoutes([
    // Main
    ...mainRoutes,

    // Auth
    ...authRoutes,

    // Dashboard
    ...dashboardRoutes,

   // Tenant not found
    {  path: 'tenant-not-found', element: 
      <TenantNotFoundLayout>
        <Suspense fallback={<SplashScreen />}>
          <TenantNotFoundPage/> 
        </Suspense>
      </TenantNotFoundLayout>
      },

   // No match
    { path: '*', element: <Navigate to="/404" replace /> },  

  ]);
}
