import { createContext, Suspense, useMemo, useCallback } from 'react';
import { SplashScreen } from 'src/components/loading-screen';
import { CheckoutContextValue, ICheckoutState, ITicket } from './types';
import { SparkTicketDto } from '../../../../../../libs/contracts/src/lib/spark/tenant/v1/ticket/ticket.dto';
import { useSetState } from 'src/hooks/use-set-state';
import { SparkWebBookingSpaceDto } from '../../../../../../libs/contracts/src/lib/spark/tenant/v1/booking/web/booking.dto';

type Props = {
  children: React.ReactNode;
};

export const CheckoutContext = createContext<CheckoutContextValue | undefined>(
  undefined,
);

export const CheckoutConsumer = CheckoutContext.Consumer;

const initialState: ICheckoutState = {
  date: new Date().toISOString(),
  slots: [],
  tickets: [],
};

export function CheckoutProvider({ children }: Props) {
  return (
    <Suspense fallback={<SplashScreen />}>
      <Container>{children}</Container>
    </Suspense>
  );
}

function Container({ children }: Props) {
  const {
    state,
    setField,
    onResetState: resetState,
  } = useSetState<ICheckoutState>(initialState);

  // const { state, setField, resetState } = useSessionStorage<ICheckoutState>(
  //   storageKey,
  //   initialState,
  // );

  const setDate = useCallback(
    (date: string) => {
      setField('date', date);
    },
    [setField],
  );

  const setSlots = useCallback(
    (slots: ICheckoutState['slots']) => {
      setField('slots', slots);
    },
    [setField],
  );

  const setTickets = useCallback(
    (tickets: ITicket[]) => {
      setField('tickets', tickets);
    },
    [setField],
  );

  const reset = useCallback(() => {
    resetState();
  }, [resetState]);

  const onIncreaseTicketQuantity = useCallback(
    (ticketId: number) => {
      const existingTicket = state.tickets.find((t) => t.ticketId === ticketId);
      const updatedTickets = existingTicket
        ? state.tickets.map((t) =>
            t.ticketId === ticketId ? { ...t, capacity: t.capacity + 1 } : t,
          )
        : [...state.tickets, { ticketId, capacity: 1 }];

      setField('tickets', updatedTickets);
    },
    [setField, state.tickets],
  );

  const onDecreaseTicketQuantity = useCallback(
    (ticketId: number) => {
      const updatedTickets = state.tickets
        .map((t) =>
          t.ticketId === ticketId
            ? { ...t, capacity: Math.max(t.capacity - 1, 0) }
            : t,
        )
        .filter((t) => t.capacity > 0);

      setField('tickets', updatedTickets);
    },
    [setField, state.tickets],
  );

  // This function iterates on tickets and calculates total prices based on tickets quantities and number of slots selected.
  const calculateTicketsBookingTotalPrice = useCallback(
    (experienceTickets: SparkTicketDto[]) => {
      let totalPrice = 0;

      state.tickets.forEach((ticket) => {
        const experienceTicketPrice = experienceTickets.find(
          (t) => t.id === ticket.ticketId,
        )?.price;

        if (experienceTicketPrice) {
          totalPrice += ticket.capacity * experienceTicketPrice;
        }
      });
      return totalPrice * state.slots.length;
    },
    [state.tickets, state.slots],
  );

  const calculateSlotsBookingTotalPrice = useCallback(() => {
    let totalPrice = 0;
    state.slots.forEach((slot) => {
      totalPrice += slot.price;
    });
    return totalPrice;
  }, [state.slots]);

  const setSpace = useCallback((space: SparkWebBookingSpaceDto) => {
    setField('space', space);
  }, [setField]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      reset,
      setDate,
      setSlots,
      setTickets,
      onIncreaseTicketQuantity,
      onDecreaseTicketQuantity,
      calculateTicketsBookingTotalPrice,
      calculateSlotsBookingTotalPrice,
      setSpace,
    }),
    [
      state,
      reset,
      setDate,
      setSlots,
      setTickets,
      onIncreaseTicketQuantity,
      onDecreaseTicketQuantity,
      calculateTicketsBookingTotalPrice,
      calculateSlotsBookingTotalPrice,
      setSpace,
    ],
  );

  return (
    <CheckoutContext.Provider value={memoizedValue}>
      {children}
    </CheckoutContext.Provider>
  );
}
