import { Box, Skeleton, Stack, Typography } from '@mui/material';

import { fDate, fDateWithoutUTC, fTime } from 'src/utils/format-time';

import { ToggleButton } from '@mui/material';

import { ToggleButtonGroup } from '@mui/material';

import { useGetExperienceSchedule } from 'src/api/web/experience';
import { useCheckoutContext } from '../checkout/context';
import { useParams } from 'src/routes/hooks';
import { useEffect } from 'react';
import { SparkWebLocationExperienceDetailsDto } from '../../../../../libs/contracts/src/lib/spark/tenant/v1/experience/experience.dto';

type Props = {
  experience: SparkWebLocationExperienceDetailsDto;
};

export default function ExperienceSlotsPicker({ experience }: Props) {
  const { date, slots, setSlots, setSpace } = useCheckoutContext();
  const { id: experienceId } = useParams();

  const { experienceSchedule, experienceScheduleLoading } =
    useGetExperienceSchedule(
      Number(experienceId),
      fDateWithoutUTC(date, 'YYYY-MM-DD') ?? '',
    );

  const handleTimeChange = (
    event: React.MouseEvent<HTMLElement>,
    newTimes: string[],
  ) => {
    const selectedSlots = newTimes.map((slot) => ({
      id: slot,
      price: 0,
      disableBooking: false,
    }));
    setSlots(selectedSlots);
  };

  useEffect(() => {
    if (experience) {
      setSpace({
        enableBookingFromMobile: experience.enableBookingFromMobile,
        enableBookingFromWebsite: experience.enableBookingFromWebsite,
        id: experience.id,
        name: experience.name,
        spaceType: experience.spaceType,
        spaceTypeValue: experience.additionalData,
        pricingType: experience.pricingType,
      });
    }
  }, [experience, setSpace]);

  if (experienceScheduleLoading) {
    return (
      <Stack spacing={3} sx={{ mt: 3 }}>
        <Typography variant="h6">Select Time</Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          {[...Array(50)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              sx={{
                minWidth: 90,
                height: 56,
                borderRadius: 1,
              }}
            />
          ))}
        </Box>
      </Stack>
    );
  }

  return (
    <Stack spacing={3} sx={{ mt: 3 }}>
      <Typography variant="h6">Select Time</Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexWrap: 'wrap',
        }}
      >
        <ToggleButtonGroup
          value={slots.map((slot) => slot.id)}
          onChange={handleTimeChange}
          aria-label="time slots"
          sx={{
            flexWrap: 'wrap',
            gap: 1,
            border: 'none',
            '& .MuiToggleButtonGroup-grouped': {
              mx: 0,
              border: 'none',
            },
          }}
        >
          {experienceSchedule.schedule.map((slot) => (
            <ToggleButton
              key={slot.id}
              value={slot.id}
              disabled={!slot.available}
              sx={{
                minWidth: 90,
                height: 56,
                flexDirection: 'column',
                typography: 'body2',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  borderColor: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                    borderColor: 'primary.dark',
                  },
                },
                '&.Mui-disabled': {
                  bgcolor: 'action.disabledBackground',
                  color: 'text.disabled',
                  borderColor: 'action.disabledBackground',
                },
                '&:hover': {
                  borderColor: 'text.primary',
                },
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: (theme) => {
                    if (!slot.available) return theme.palette.text.disabled;
                    return slots.some((s) => s.id === slot.id)
                      ? 'white'
                      : 'text.primary';
                  },
                }}
              >
                {fTime(slot.from, 'hh:mm A')}
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Stack>
  );
}
