import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';

import { CharCapitalize } from 'src/utils/change-case';

import { varHover } from 'src/components/animate';
import { CustomPopover, usePopover } from 'src/components/custom-popover';
import { useUserAuthContext } from 'src/user-auth/hooks';
import { signOut } from 'src/user-auth/context/jwt';
import { Button, ListItemText } from '@mui/material';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UserAccountPopover() {
  const router = useRouter();

  const { user, checkUserSession, authenticated } = useUserAuthContext();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await signOut();
      await checkUserSession?.();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  if (!authenticated) {
    return (
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            borderRadius: 3,
            width: 80,
            height: 40,
          }}
          onClick={() => router.push(paths.userAuth.signIn)}
        >
          Register
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: 3,
            width: 80,
            height: 40,
          }}
          onClick={() => router.push(paths.userAuth.signIn)}
        >
          Sign In
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          alt={user?.firstName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.firstName ? CharCapitalize(user?.firstName?.charAt(0)) : ''}
        </Avatar>
      </IconButton>

      <CustomPopover
        anchorEl={popover.anchorEl}
        open={popover.open}
        onClose={popover.onClose}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <ListItemText
            primary={user?.email}
            secondary={user?.phoneNumber}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'body2',
            }}
            secondaryTypographyProps={{
              noWrap: true,
              color: 'text.secondary',
              variant: 'caption',
            }}
          />
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
