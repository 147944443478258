import useSWR from 'swr';

import { endpoints, fetcher, reValidate, axiosInstance } from 'src/utils/axios';
import { useMemo } from 'react';
import { SparkAddTenantContactNumberDto, SparkUpdateTenantMediaDto } from '../../../../libs/contracts/src';
import { SparkTenantDto } from '../../../../libs/contracts/src/lib/spark/super-admin/v1/tenant/tenant.dto';
import { ContactItem } from 'src/sections/contacts/view/contacts-view';


// ----------------------------------------------------------------------


export function useGetCurrentTenant(){
  const URL = endpoints.tenants.me;
  const { data, isLoading ,error} = useSWR(URL, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  
   
  const memoizedValue = useMemo(
    () => ({
      tenant:(data?.data as SparkTenantDto ) || undefined,
      tenantLoading: isLoading,
      onError:  error || null,
    }),
    [data?.data, isLoading],
  );

  return memoizedValue ;
}

export async function addTenantMedia(data: SparkUpdateTenantMediaDto) {
  const URL = endpoints.tenants.addMedia;
  await axiosInstance.patch(URL, data);
  reValidate(endpoints.tenants.key);
}

export async function deleteTenantMedia(mediaId: number) {
  const URL = endpoints.tenants.deleteMedia(mediaId);
  await axiosInstance.delete(URL);
  reValidate(endpoints.tenants.key);
}

export function UseGetContacts(){
  const URL= endpoints.tenants.contacts.me;
  const { data, isLoading, error, isValidating, mutate } = useSWR(
    URL,
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  const memoizedValue = useMemo(
    () => ({
      
      contacts:(data?.data as ContactItem[]) || [],
      contactsLoading: isLoading,
      contactsError: error,
      contactsValidating: isValidating,
      contactsEmpty: !isLoading && !data?.data?.length,
      mutate,
    }),
    [data?.data, error, isLoading, isValidating, mutate],
  );

  return memoizedValue;
}



export async function addContact(data: SparkAddTenantContactNumberDto){
  const URL= endpoints.tenants.contacts.me;
  await axiosInstance.post(URL, data);
  reValidate(endpoints.tenants.key);

}

export async function updateContact(id: number, data: SparkAddTenantContactNumberDto) {
  const URL = endpoints.tenants.contacts.updateContact(id)
  await axiosInstance.put(URL, data);
  reValidate(endpoints.tenants.contacts.key);
}

export async function deleteContact(id: number) {
  const URL = endpoints.tenants.contacts.deleteContact(id)
  await axiosInstance.delete(URL);
  reValidate(endpoints.tenants.contacts.key);
}