import { Box, Stack, ToggleButton, Typography } from '@mui/material';
import { addDays } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { fDate } from 'src/utils/format-time';
import { useCheckoutContext } from '../checkout/context';
import dayjs from 'dayjs';

// TODO:: MISSING Handle the end date of time slots to be fetched from backend.

export default function ExperienceDatePicker() {
  const {
    date: selectedDate,
    setDate: setSelectedDate,
    setTickets,
    setSlots,
  } = useCheckoutContext();

  const next7Days = useMemo(() => {
    return [...Array(7)].map((_, index) => {
      const date = dayjs().add(index, 'day');
      return {
        date: date.toDate(),
        dayName: date.format('ddd'),
        dayNumber: date.format('DD'),
      };
    });
  }, []);

  // When selecting date reset tickets and slot ids.
  const handleDateChange = (date: Date) => {
    setSelectedDate(date.toString());
    setTickets([]);
    setSlots([]);
  };

  return (
    <Stack spacing={3} sx={{ mt: 3 }}>
      <Typography variant="h6">Select Date</Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexWrap: 'wrap',
        }}
      >
        {next7Days.map(({ date, dayName, dayNumber }) => (
          <ToggleButton
            key={date.toString()}
            value={date}
            selected={
              fDate(selectedDate, 'YYYY-MM-DD') === fDate(date, 'YYYY-MM-DD')
            }
            onChange={() => handleDateChange(date)}
            sx={{
              minWidth: 64,
              height: 72,
              flexDirection: 'column',
              typography: 'body2',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              '&.Mui-selected': {
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                borderColor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark',
                  borderColor: 'primary.dark',
                },
              },
              '&:hover': {
                borderColor: 'text.primary',
              },
            }}
          >
            <Typography variant="h6">{dayNumber}</Typography>
            <Typography variant="subtitle2">{dayName}</Typography>
          </ToggleButton>
        ))}
      </Box>
    </Stack>
  );
}
