import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { MainContent } from 'src/layouts/main';
import { useRouter } from 'src/routes/hooks';

import { Iconify } from 'src/components/iconify';
import { paths } from 'src/routes/paths';

export default function BookingSuccessView() {
  const router = useRouter();
  return (
    <MainContent>
      <Container>
        <Box
          sx={{
            py: 12,
            maxWidth: 480,
            mx: 'auto',
            textAlign: 'center',
          }}
        >
          <Box
            component="img"
            src="/assets/images/mockups/app.png"
            sx={{
              width: { xs: 240, md: 360 },
              height: { xs: 240, md: 360 },
              mx: 'auto',
              mb: 5,
            }}
          />

          <Typography variant="h3" sx={{ mb: 2 }}>
            Booking Successful!
          </Typography>

          <Typography variant="body1" sx={{ color: 'text.secondary', mb: 5 }}>
            Your booking has been confirmed. Download the Spark app to view all
            your bookings and get instant updates.
          </Typography>

          <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="center"
          >
            <Button
              variant="contained"
              startIcon={<Iconify icon="ri:google-play-fill" />}
              href="https://play.google.com/store/apps/details?id=com.apessmartsolutions.spark"
              target="_blank"
              rel="noopener"
            >
              Get it on Play Store
            </Button>

            <Button
              variant="contained"
              startIcon={<Iconify icon="ri:app-store-fill" />}
              href="https://apps.apple.com/ae/app/spark-entertainment/id6739362680"
              target="_blank"
              rel="noopener"
            >
              Download on App Store
            </Button>
          </Stack>

          <Button
            color="primary"
            variant="outlined"
            sx={{ mt: 5 }}
            onClick={() => router.push(paths.tenant.root)}
          >
            Explore More Experiences
          </Button>
        </Box>
      </Container>
    </MainContent>
  );
}
