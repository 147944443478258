import { useCallback, useEffect, useMemo } from 'react';

import { useSetState } from 'src/hooks/use-set-state';

import { UserAuthContext } from '../user-auth-context';

import type { AuthState } from '../../types';
import { STORAGE_KEY } from './constant';
import { isValidToken, setSession } from './utils';
import { endpoints, webAxiosInstance } from 'src/utils/axios';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */

type Props = {
  children: React.ReactNode;
};

export function UserAuthProvider({ children }: Props) {
  const { state, setState } = useSetState<AuthState>({
    user: null,
    loading: true,
  });

  const router = useRouter();
  // ----------------------------------------------------------------------
  const checkUserSession = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const res = await webAxiosInstance.get(endpoints.web.auth.me);

        const user = res.data.data;

        setState({ user: { ...user, accessToken }, loading: false });
        if (res.status === 240) {
          router.replace(paths.userAuth.completeProfile);
        }
      } else {
        setState({ user: null, loading: false });
      }
    } catch (error) {
      console.error(error);
      setState({ user: null, loading: false });
    }
  }, [setState]);

  useEffect(() => {
    checkUserSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user ? state.user : null,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      checkUserSession,
    }),
    [state.user, status],
  );

  return (
    <UserAuthContext.Provider value={memoizedValue}>
      {children}
    </UserAuthContext.Provider>
  );
}
