import { useMemo, createContext, useEffect } from 'react';
import { useRouter } from 'src/routes/hooks';
import type {
  CurrentTenantContextValue,
  CurrentTenantProviderProps,
} from '../types';
import { useGetCurrentTenant } from 'src/api/tenant';
import { SplashScreen } from 'src/components/loading-screen';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export const CurrentTenantContext = createContext<
  CurrentTenantContextValue | undefined
>(undefined);

export const CurrentTenantConsumer = CurrentTenantContext.Consumer;

// ----------------------------------------------------------------------

export function CurrentTenantProvider({
  children,
}: CurrentTenantProviderProps) {
  const { tenant,tenantLoading,onError} = useGetCurrentTenant();


  const router =useRouter()

  const tenantNotFoundPath = paths.tenantNotFound.root;


    // In case an error occurs redirect the user to tenant not found page 
    useEffect(() => {
      
      if (onError) {
        router.replace(tenantNotFoundPath);
      }
    }, [onError, router]);
    
  const memoizedValue = useMemo(
    () => ({
      tenant: tenantLoading ? undefined : tenant,
    }),
    [tenant, tenantLoading],
  );

  useEffect(() => {
    if (tenant) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = tenant.logo.path;
    }
  }, [tenant]);
  return (
    <CurrentTenantContext.Provider value={memoizedValue}>
      {tenantLoading ? <SplashScreen /> : children}
    </CurrentTenantContext.Provider>
  );
}
