// ----------------------------------------------------------------------

const ROOTS = {
  TENANT: '/',
  USER_AUTH: '/auth',
  AUTH: '/dashboard/auth',
  DASHBOARD: '/dashboard',
  PROFILE: '/dashboard/profile',
  LOCATIONS: '/dashboard/locations',
  SPACES: '/dashboard/spaces',
  BOOKINGS: '/dashboard/bookings',
  TRANSACTIONS: '/dashboard/transactions',
  EVENTS: '/dashboard/events',
  CLASSES: '/dashboard/classes',
  ADMINS: '/dashboard/admins',
  ROLES: '/dashboard/roles',
  SETTINGS: '/dashboard/settings',
  CUSTOMERS: '/dashboard/customers',
  TENANT_NOT_FOUND: '/tenant-not-found',
};

// ----------------------------------------------------------------------

export const paths = {
  // TENANT DETAILS
  tenant: {
    root: ROOTS.TENANT,
    tenantLocation: {
      root: `${ROOTS.TENANT}locations`,
      details: (id: number) => `${ROOTS.TENANT}locations/${id}`,
    },
    experiences: {
      details: (id: number) => `${ROOTS.TENANT}experiences/${id}`,
    },
    booking: {
      success: `${ROOTS.TENANT}booking/success`,
    },
  },
  // USER AUTH
  userAuth: {
    signIn: `${ROOTS.USER_AUTH}/sign-in`,
    verifyOtp: `${ROOTS.USER_AUTH}/verify-otp`,
    completeProfile: `${ROOTS.USER_AUTH}/complete-profile`,
  },
  // AUTH
  auth: {
    signIn: `${ROOTS.AUTH}/sign-in`,
    forgetPassword: `${ROOTS.AUTH}/forget-password`,
    resetPassword: `${ROOTS.AUTH}/reset-password`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.LOCATIONS,
  },
  // PROFILE
  profile: {
    root: ROOTS.PROFILE,
  },
  // LOCATIONS
  locations: {
    root: ROOTS.LOCATIONS,
    new: `${ROOTS.LOCATIONS}/new`,
    edit: (id: number) => `${ROOTS.LOCATIONS}/${id}/edit`,
    details: (id: number) => `${ROOTS.LOCATIONS}/${id}`,
    spaces: {
      new: (id: string) => `${ROOTS.LOCATIONS}/${id}/spaces/new`,
      view: (id: string, spaceId: string) =>
        `${ROOTS.LOCATIONS}/${id}/spaces/${spaceId}`,
    },
  },
  spaces: {
    root: ROOTS.SPACES,
    new: `${ROOTS.SPACES}/new`,
    edit: (id: number) => `${ROOTS.SPACES}/${id}/edit`,
    details: (id: number) => `${ROOTS.SPACES}/${id}`,
  },
  // BOOKINGS
  bookings: {
    root: `${ROOTS.BOOKINGS}/list`,
    calendar: `${ROOTS.BOOKINGS}/calendar`,
  },
  // TRANSACTIONS
  transactions: {
    root: `${ROOTS.TRANSACTIONS}`,
  },
  // EVENTS
  events: {
    root: ROOTS.EVENTS,
    details: (id: number) => `${ROOTS.EVENTS}/${id}`,
    new: `${ROOTS.EVENTS}/new`,
  },
  // CLASSES
  classes: {
    root: `${ROOTS.CLASSES}/calendar`,
    list: `${ROOTS.CLASSES}/list`,
    details: (classId: number, sessionId: number) =>
      `${ROOTS.CLASSES}/${classId}/sessions/${sessionId}`,
    edit: (classId: number) => `${ROOTS.CLASSES}/${classId}/edit`,
    editSession: (classId: number, sessionId: number) =>
      `${ROOTS.CLASSES}/${classId}/sessions/${sessionId}/edit`,
    new: `${ROOTS.CLASSES}/new`,
  },
  // CUSTOMERS
  customers: {
    root: `${ROOTS.CUSTOMERS}`,
    view: (id: number) => `${ROOTS.CUSTOMERS}/${id}/view`,
  },
  // ADMINS
  admins: {
    root: ROOTS.ADMINS,
    new: `${ROOTS.ADMINS}/new`,
    edit: (id: number) => `${ROOTS.ADMINS}/${id}/edit`,
  },
  // ROLES
  roles: {
    root: ROOTS.ROLES,
    new: `${ROOTS.ROLES}/new`,
    edit: (id: number) => `${ROOTS.ROLES}/${id}/edit`,
  },
  // SETTINGS
  settings: {
    root: ROOTS.SETTINGS,
    gallery: `${ROOTS.SETTINGS}/gallery`,
    contacts: `${ROOTS.SETTINGS}/contacts`,
  },
  tenantNotFound: {
    root: ROOTS.TENANT_NOT_FOUND,
  },
};
