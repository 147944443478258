export function paramCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
}

export function snakeCase(str: string) {
  return str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '');
}

export function snakeCaseToTitle(str: string) {
  return str
    .split('_') // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(' ');
}

export function firstCharCapitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatShortName(name: string) {
  return name.replace(/\s+/g, '').toUpperCase();
}
export function CharCapitalize(str: string) {
  return str.toUpperCase();
}

export function charSubStr(str: string, end: number) {
  return str.substring(0, end);
}

export function capitalizeString(status: string): string {
  // Split the string at each uppercase letter
  const words = status.replace(/([A-Z])/g, ' $1').trim();

  // Capitalize the first letter of each word
  const formattedWords = words
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // Join the words back into a single string
  return formattedWords.join(' ');
}

export const formatEnum = (enumValue: string) => {
  return enumValue
    .split('_') // Split by underscore
    .map(
      (
        word, // Capitalize each word
      ) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(' '); // Join the words with a space
};
