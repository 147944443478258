import { useState, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';

import { NavList } from './nav-list';
import { navSectionClasses } from '../classes';
import { navSectionCssVars } from '../css-vars';
import { NavUl, NavLi, Subheader } from '../styles';

import type { NavGroupProps, NavSectionProps } from '../types';
import { NavGroupPermissionGuard } from '../nav-group-permission-guard';
import { PermissionGuard } from 'src/auth/guard';
import { useTranslate } from 'src/locales';
// ----------------------------------------------------------------------

export function NavSectionVertical({
  sx,
  data,
  render,
  slotProps,
  enabledRootRedirect,
  cssVars: overridesVars,
}: NavSectionProps) {
  const theme = useTheme();

  const cssVars = {
    ...navSectionCssVars.vertical(theme),
    ...overridesVars,
  };

  return (
    <Stack
      component="nav"
      className={navSectionClasses.vertical.root}
      sx={{ ...cssVars, ...sx }}
    >
      <NavUl sx={{ flex: '1 1 auto', gap: 'var(--nav-item-gap)' }}>
        {data.map((group) => (
          <Group
            key={group.subheader ?? group.items[0].title}
            subheader={group.subheader}
            items={group.items}
            render={render}
            slotProps={slotProps}
            enabledRootRedirect={enabledRootRedirect}
          />
        ))}
      </NavUl>
    </Stack>
  );
}

// ----------------------------------------------------------------------

function Group({
  items,
  render,
  subheader,
  slotProps,
  enabledRootRedirect,
}: NavGroupProps) {
  const { t: tNavbar } = useTranslate('navbar');
  const [open, setOpen] = useState(true);
  const permissonRequeired = items.map((i) => i.permission);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const renderContent = (
    <NavUl sx={{ gap: 'var(--nav-item-gap)' }}>
      {items.map((list) => (
        <PermissionGuard key={list.title} requiredPermission={list.permission}>
          <NavList
            data={list}
            render={render}
            depth={1}
            slotProps={slotProps}
            enabledRootRedirect={enabledRootRedirect}
          />
        </PermissionGuard>
      ))}
    </NavUl>
  );

  return (
    <NavGroupPermissionGuard requiredPermissions={permissonRequeired}>
      <NavLi>
        {subheader ? (
          <>
            <Subheader
              data-title={subheader}
              open={open}
              onClick={handleToggle}
              sx={slotProps?.subheader}
            >
              {tNavbar(`navbar.${subheader}`)}
            </Subheader>

            <Collapse in={open}>{renderContent}</Collapse>
          </>
        ) : (
          renderContent
        )}
      </NavLi>
    </NavGroupPermissionGuard>
  );
}
