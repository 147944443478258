import type { BoxProps } from '@mui/material/Box';
import type { Breakpoint } from '@mui/material/styles';
import type { ContainerProps } from '@mui/material/Container';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

import { layoutClasses } from 'src/layouts/classes';

import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { onMessage } from 'firebase/messaging';
import { messaging } from 'src/lib/firebase';
import { toast } from 'src/components/snackbar';
import { endpoints, reValidate } from '../../utils/axios';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

type MainProps = BoxProps & {
  isNavHorizontal: boolean;
};

export function Main({ children, isNavHorizontal, sx, ...other }: MainProps) {
  const { user } = useAuthContext();
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      toast.info(payload.notification?.title ?? '');
      reValidate(endpoints.notifications.reValidateKey);
      // Customize notification display here

      const notificationTitle = payload.notification?.title;
      const notificationOptions = {
        body: payload.notification?.body,
        tag: notificationTitle,
        icon: '/logo/logo-single.png',
        badge: '/logo/logo-single.png',
        image: '/logo/logo-single.png',
      };
      if ('serviceWorker' in navigator && user) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.active?.postMessage({
            type: 'SHOW_NOTIFICATION',
            payload: { title: notificationTitle, options: notificationOptions },
          });
        });
      }
    });

    if ('serviceWorker' in navigator && user) {
      navigator.serviceWorker
        .register('/assets/firebase/firebase-messaging-sw.js')
        .then((registration) => {
          // console.log('Service Worker registered:', registration);
        })
        .catch((error) => {
          // console.error('Service Worker registration failed:', error);
        });

      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'PLAY_AUDIO') {
          const audio = new Audio('/assets/firebase/notification.wav'); // Ensure this file exists in /public
          audio.play().catch((err) => console.error('Audio play failed', err));
        }
      });
    }
    return () => {
      unsubscribe();
    };
  }, [user]);
  return (
    <Box
      component="main"
      className={layoutClasses.main}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        ...(isNavHorizontal && {
          '--layout-dashboard-content-pt': '40px',
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

// ----------------------------------------------------------------------

type DashboardContentProps = ContainerProps & {
  disablePadding?: boolean;
};

export function DashboardContent({
  sx,
  children,
  disablePadding,
  maxWidth = 'lg',
  ...other
}: DashboardContentProps) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const layoutQuery: Breakpoint = 'lg';

  return (
    <Container
      className={layoutClasses.content}
      maxWidth={settings.compactLayout ? maxWidth : false}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        pt: 'var(--layout-dashboard-content-pt)',
        pb: 'var(--layout-dashboard-content-pb)',
        [theme.breakpoints.up(layoutQuery)]: {
          px: 'var(--layout-dashboard-content-px)',
        },
        ...(disablePadding && {
          p: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0,
          },
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Container>
  );
}
