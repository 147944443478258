import type { AxiosRequestConfig } from 'axios';

import axios from 'axios';

import { CONFIG } from 'src/config-global';
import { paths } from 'src/routes/paths';
import { mutate } from 'swr';

// ----------------------------------------------------------------------

export const axiosInstance = axios.create({
  baseURL: CONFIG.site.serverUrl,
  headers: {
    custom: CONFIG.api.customHeader,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong!',
    ),
);

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  try {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await axiosInstance.get(url, {
      ...config,
    });

    return res.data;
  } catch (error) {
    console.error('Failed to fetch:', error);
    throw error;
  }
};

// ----------------------------------------------------------------------

export const webAxiosInstance = axios.create({
  baseURL: CONFIG.site.serverUrl,
  headers: {
    custom: CONFIG.api.customHeader,
  },
});

webAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors
    if (error.response && error.response.status === 401) {
      // Redirect to the login page on 401 errors with returnTo parameter
      const returnTo = window.location.pathname + window.location.search;
      const loginUrl = `${paths.userAuth.signIn}?returnTo=${encodeURIComponent(returnTo)}`;
      window.location.href = loginUrl;
    }
    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong!',
    );
  },
);

// ----------------------------------------------------------------------

export const webFetcher = async (
  args: string | [string, AxiosRequestConfig],
) => {
  try {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await webAxiosInstance.get(url, {
      ...config,
    });

    return res.data;
  } catch (error) {
    console.error('Failed to fetch:', error);
    throw error;
  }
};

// ----------------------------------------------------------------------

export function reValidate(endpoint: any) {
  mutate(
    (key: any) => (
      (typeof key === 'string' && key.includes(endpoint)) ||
        // eslint-disable-next-line no-sequences
        (Array.isArray(key) && key[0].includes(endpoint)),
      undefined,
      { revalidate: true }
    ),
  );
}

// ----------------------------------------------------------------------
export function addServerErrors<T>(
  errors: { [P in keyof T]?: string },
  setError: (
    fieldName: keyof T,
    error: { type: string; message: string },
  ) => void,
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: 'server',
      message: errors[key as keyof T] ?? '',
    });
  });
}

// ----------------------------------------------------------------------

export const endpoints = {
  web: {
    auth: {
      sendOtp: '/api/v1/web/auth/send-otp',
      verifyOtp: '/api/v1/web/auth/verify-otp',
      completeProfile: '/api/v1/web/auth/complete-profile',
      me: '/api/v1/web/auth/me',
    },
    locations: {
      key: '/api/v1/web/locations',
      list: '/api/v1/web/locations',
      details: (locationId: string) => `/api/v1/web/locations/${locationId}`,
      spaces: (locationId: number) =>
        `/api/v1/web/locations/${locationId}/spaces`,
    },
    experiences: {
      list: '/api/v1/web/experiences',
      details: (experienceId: number) =>
        `/api/v1/web/experiences/${experienceId}`,
      slots: (experienceId: number) =>
        `/api/v1/web/experiences/${experienceId}/slots`,
    },
    bookings: {
      book: '/api/v1/web/bookings',
      cancelBooking: (bookingId: number) =>
        `/api/v1/web/bookings/${bookingId}/cancel`,
    },
  },
  tenants: {
    key: 'tenants',
    me: '/api/v1/tenants/me',
    addMedia: '/api/v1/tenants/me/media',
    deleteMedia: (tenantMediaId: number) =>
      `/api/v1/tenants/me/media/${tenantMediaId}`,
    contacts: {
      key: 'tenants.contacts',
      me: '/api/v1/tenants/me/contact-numbers',
      deleteContact: (tenantContactNumberId: number) =>
        `/api/v1/tenants/me/contact-numbers/${tenantContactNumberId}`,
      updateContact: (tenantContactNumberId: number) =>
        `/api/v1/tenants/me/contact-numbers/${tenantContactNumberId}`,
    },
  },
  core: {
    amenities: '/api/v1/core/amenities',
    cities: '/api/v1/core/cities',
    zones: (cityId: string) => `/api/v1/core/cities/${cityId}`,
    tags: '/api/v1/core/tags',
    categories: '/api/v1/core/categories',
  },
  file: {
    upload: '/api/v1/files/upload',
    uploadFiles: '/api/v1/files/upload-files',
  },
  locations: {
    key: 'locations',
    add: '/api/v1/locations',
    spaces: (locationId: number) => `/api/v1/locations/${locationId}/spaces`,
    edit: (locationId: string) => `/api/v1/locations/${locationId}`,
    delete: (locationId: string) => `/api/v1/locations/${locationId}`,
    list: '/api/v1/locations',
    details: (locationId: string) => `/api/v1/locations/${locationId}`,
    toggleStatus: (locationId: string) =>
      `/api/v1/locations/${locationId}/toggle-status`,
    short: '/api/v1/locations/list',
    reorder: '/api/v1/locations/reorder',
    space: {
      reorder: (locationId: number) =>
        `/api/v1/locations/${locationId}/spaces/reorder`,
    },
  },
  spaces: {
    key: 'spaces',
    add: '/api/v1/spaces',
    edit: (spaceId: string) => `/api/v1/spaces/${spaceId}`,
    list: '/api/v1/spaces',
    details: (spaceId: string) => `/api/v1/spaces/${spaceId}`,
    delete: (spaceId: string) => `/api/v1/spaces/${spaceId}`,
    toggleStatus: (spaceId: string) =>
      `/api/v1/spaces/${spaceId}/toggle-status`,
    addAvailability: (spaceId: string) =>
      `/api/v1/spaces/${spaceId}/availability-rules`,
    addMedia: (spaceId: number) => `/api/v1/spaces/${spaceId}/media`,
    deleteMedia: (spaceId: number, spaceMediaId: number) =>
      `/api/v1/spaces/${spaceId}/media/${spaceMediaId}`,
    deleteAvailability: (spaceId: string, ruleId: string) =>
      `/api/v1/spaces/${spaceId}/availability-rules/${ruleId}`,
    getSpacePricing: (spaceId: number) =>
      `/api/v1/spaces/${spaceId}/price-rules`,
    addPricing: (spaceId: number) => `/api/v1/spaces/${spaceId}/price-rules`,
    updatePricing: (spaceId: number, priceRuleId: number) =>
      `/api/v1/spaces/${spaceId}/price-rules/${priceRuleId}`,
    reorderPricingRules: (spaceId: number) =>
      `/api/v1/spaces/${spaceId}/price-rules/reorder`,
    deletePricing: (spaceId: string, ruleId: string) =>
      `/api/v1/spaces/${spaceId}/price-rules/${ruleId}`,
    types: '/api/v1/core/space-types',
  },
  tickets: {
    add: '/api/v1/tickets',
    list: '/api/v1/tickets',
    listSort: '/api/v1/tickets/list',
    details: (ticketId: number) => `/api/v1/tickets/${ticketId}`,
    edit: (ticketId: number) => `/api/v1/tickets/${ticketId}`,
    delete: (ticketId: number) => `/api/v1/tickets/${ticketId}`,
    toggleStatus: (ticketId: number) =>
      `/api/v1/tickets/${ticketId}/toggle-status`,
  },
  auth: {
    me: '/api/v1/auth/me',
    login: '/api/v1/auth/login',
    logout: '/api/v1/auth/logout',
    updateFcmToken: '/api/v1/auth/update-fcm-token',
    resetPassword: '/api/v1/auth/reset-password',
    forgetPassword: '/api/v1/auth/forget-password',
    changePassword: '/api/v1/auth/change-password',
  },
  notifications: {
    list: '/api/v1/notifications',
    markAllAsRead: '/api/v1/notifications/mark-all-as-read',
    reValidateKey: 'notifications',
  },
  bookings: {
    reValidateKey: 'bookings',
    list: '/api/v1/bookings',
    calender: '/api/v1/bookings/calender',
    create: '/api/v1/bookings',
    update: (id: string) => `/api/v1/bookings/${id}`,
    complete: (id: string) => `/api/v1/bookings/${id}/complete`,
    contact: (id: string) => `/api/v1/bookings/${id}/mark-as-contacted`,
    miss: (id: string) => `/api/v1/bookings/${id}/mark-as-missed`,
    cancel: (id: string) => `/api/v1/bookings/${id}/cancel`,
    createRecurring: '/api/v1/bookings/recurrences',
    import: '/api/v1/bookings/import',
    deleteRecurring: (id: number) => `/api/v1/bookings/recurrences/${id}`,
  },
  auditLogs: {
    list: '/api/v1/audit-trails',
  },
  profile: {
    update: '/api/v1/admins/me',
  },
  class: {
    key: 'classes',
    sessions: '/api/v1/classes/sessions/many',
    sessionsList: '/api/v1/classes/sessions',
    createSessionBooking: (eventId: number, sessionId: number) =>
      `/api/v1/classes/${eventId}/sessions/${sessionId}/bookings`,
    sessionBookings: `/api/v1/classes/sessions/bookings`,
    add: '/api/v1/classes',
    edit: (id: number) => `/api/v1/classes/${id}`,
    editSession: (id: number, sessionId: number) =>
      `/api/v1/classes/${id}/sessions/${sessionId}`,
    details: (classId: number) => `/api/v1/classes/${classId}`,
    sessionDetails: (classId: number, sessionId: number) =>
      `/api/v1/classes/${classId}/sessions/${sessionId}`,
    relatedSessions: (classId: number, sessionId: number) =>
      `/api/v1/classes/${classId}/sessions/${sessionId}/related`,
    deleteRecurrence: (classId: number, recurrenceId: number) =>
      `/api/v1/classes/${classId}/recurrences/${recurrenceId}`,
    deleteOccurrence: (classId: number, sessionId: number) =>
      `/api/v1/classes/${classId}/sessions/${sessionId}`,
    media: {
      delete: (eventId: number, mediaId: number) =>
        `/api/v1/classes/${eventId}/media/${mediaId}`,
      edit: (eventId: number) => `/api/v1/classes/${eventId}/media`,
    },
  },
  events: {
    key: 'events',
    list: '/api/v1/events',
    details: (eventId: string) => `/api/v1/events/${eventId}`,
    add: '/api/v1/events',
    edit: (eventId: string) => `/api/v1/events/${eventId}`,
    delete: (eventId: string) => `/api/v1/events/${eventId}`,
    sessionsList: (eventId: string) => `/api/v1/events/${eventId}/sessions`,
    addSession: (eventId: string) => `/api/v1/events/${eventId}/sessions`,
    editSession: (eventId: string, sessionId: string) =>
      `/api/v1/events/${eventId}/sessions/${sessionId}`,
    deleteSession: (eventId: string, sessionId: string) =>
      `/api/v1/events/${eventId}/sessions/${sessionId}`,
    addRecurrent: (eventId: string) => `/api/v1/events/${eventId}/recurrences`,
    deleteRecurrent: (eventId: string, recurrentId: string) =>
      `/api/v1/events/${eventId}/recurrences/${recurrentId}`,
    addPricing: (eventId: string) => `/api/v1/events/${eventId}/price-rules`,
    deletePricing: (eventId: string, ruleId: string) =>
      `/api/v1/events/${eventId}/price-rules/${ruleId}`,
  },
  transactions: {
    list: '/api/v1/transactions',
    statistics: '/api/v1/transactions/statistics',
    export: '/api/v1/transactions/export',
  },
  admins: {
    key: 'admins',
    list: '/api/v1/admins',
    add: '/api/v1/admins',
    details: (adminId: string) => `/api/v1/admins/${adminId}`,
    edit: (adminId: string) => `/api/v1/admins/${adminId}`,
    delete: (adminId: string) => `/api/v1/admins/${adminId}`,
    toggleStatus: (adminId: string) =>
      `/api/v1/admins/${adminId}/toggle-status`,
  },
  roles: {
    key: 'roles',
    list: '/api/v1/roles',
    meta: '/api/v1/roles/metadata',
    add: '/api/v1/roles',
    details: (roleId: string) => `/api/v1/roles/${roleId}`,
    edit: (roleId: string) => `/api/v1/roles/${roleId}`,
    delete: (roleId: string) => `/api/v1/roles/${roleId}`,
  },
  customers: {
    key: 'tenant-users',
    list: '/api/v1/tenant-users',
    add: '/api/v1/tenant-users',
    search: '/api/v1/tenant-users/search',
    findByPhoneNumber: `/api/v1/users/find-one`,
    toggleStatus: (id: number) => `/api/v1/tenant-users/${id}/toggle-status`,
    details: (customerId: number) => `/api/v1/tenant-users/${customerId}`,
  },
};
