import 'src/global.css';

// ----------------------------------------------------------------------

import { Router } from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import { ThemeProvider } from 'src/theme/theme-provider';

import { Snackbar } from 'src/components/snackbar';
import { ProgressBar } from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import {
  SettingsDrawer,
  defaultSettings,
  SettingsProvider,
} from 'src/components/settings';

import { AuthProvider } from 'src/auth/context/jwt';
import { LocalizationProvider } from './locales/localization-provider';
import { CurrentTenantProvider } from './current-tenant';
import { UserAuthProvider } from './user-auth/context/jwt';
import { I18nProvider } from './locales';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <SettingsProvider settings={defaultSettings}>
      <I18nProvider>
        <LocalizationProvider>
          <UserAuthProvider>
            <AuthProvider>
              <ThemeProvider>
                <MotionLazy>
                  <CurrentTenantProvider>
                    <Snackbar />
                    <ProgressBar />
                    <SettingsDrawer />
                    <Router />
                  </CurrentTenantProvider>
                </MotionLazy>
              </ThemeProvider>
            </AuthProvider>
          </UserAuthProvider>
        </LocalizationProvider>
      </I18nProvider>
    </SettingsProvider>
  );
}
