import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { updateFcmToken } from '../api/notification';
import { CONFIG } from 'src/config-global';

let messaging: any;
if (!navigator.userAgent.includes('Instagram')) {
  const app = initializeApp(CONFIG.firebase);
  messaging = getMessaging(app);
  onMessage(messaging, ({ notification }) => {
    if (notification) {
      new Notification(notification.title!, {
        body: notification.body,
        icon: notification.icon,
      });
    }
  });
}

export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: CONFIG.firebase.vapidKey,
      });

      if (token) {
        await updateFcmToken({
          fcmToken: token,
        });
      }

      return token;
    } else {
      console.warn('Notification permission denied');
    }
  } catch (error) {
    console.error('Failed to get notification permission:', error);
    return null;
  }
};

export { messaging };
