import { useState, useEffect } from 'react';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';

type Props = {
  children: React.ReactNode;
  requiredPermission: string | string[];
  isPage?: boolean;
};

export function PermissionGuard({
  children,
  requiredPermission,
  isPage,
}: Props) {
  const router = useRouter();
  const [hasPermission, setHasPermission] = useState<boolean>(true);
  const { user } = useAuthContext();

  useEffect(() => {
    const checkPermission = () => {
      if (!user) {
        setHasPermission(false);
        return;
      }

      if (user.isSuper) {
        setHasPermission(true);
      } else {
        if (Array.isArray(requiredPermission)) {
          const hasRequiredPermission = requiredPermission.some((permission) =>
            user.permissions.includes(permission),
          );
          setHasPermission(hasRequiredPermission);
        } else {
          const hasRequiredPermission =
            user.permissions.includes(requiredPermission);
          setHasPermission(hasRequiredPermission);
        }
      }
    };

    checkPermission();
  }, [requiredPermission, user]);

  useEffect(() => {
    if (!hasPermission && isPage) {
      router.replace('/403');
    }
  }, [hasPermission, isPage, router]);

  if (!hasPermission && !isPage) {
    return null;
  }

  return children;
}
