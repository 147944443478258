import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

type RegisterLoginDialogProps = {
  open: boolean;
  onClose: () => void;
  onRegister: () => void;
  onLogin: () => void;
};

const RegisterLoginDialog: React.FC<RegisterLoginDialogProps> = ({
  open,
  onClose,
  onRegister,
  onLogin,
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>Register / Sign In</DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          In order to complete your booking, please register or sign in first.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRegister} variant="outlined" color="primary">
          Register
        </Button>
        <Button onClick={onLogin} variant="contained" color="primary">
          Sign In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterLoginDialog;
