import { useMemo } from 'react';
import { endpoints, webFetcher } from 'src/utils/axios';
import useSWR from 'swr';
import {
  SparkWebLocationExperienceDetailsDto,
  SparkWebLocationExperienceDto,
} from '../../../../../libs/contracts/src/lib/spark/tenant/v1/experience/experience.dto';
import { SparkWebExperienceScheduleDto } from '../../../../../libs/contracts/src/lib/spark/tenant/v1/experience/experience-slots.dto';

export function useGetExperiences({
  tenantId,
  locationId,
}: {
  tenantId?: number;
  locationId?: number;
}) {
  const URL = [
    endpoints.web.experiences.list,
    { params: { tenantId, locationId } },
  ];

  const { data, isLoading, error, isValidating, mutate } = useSWR(
    URL,
    webFetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );
  const memoizedValue = useMemo(
    () => ({
      experiences: (data?.data as SparkWebLocationExperienceDto[]) || [],
      experiencesLoading: isLoading,
      experiencesError: error,
      experiencesValidating: isValidating,
      experiencesEmpty: !isLoading && !data?.data.length,
      mutate,
    }),
    [data?.data, error, isLoading, isValidating, mutate],
  );

  return memoizedValue;
}

export function useGetExperience(experienceId: number) {
  const URL = endpoints.web.experiences.details(experienceId);

  const { data, isLoading, error, isValidating, mutate } = useSWR(
    URL,
    webFetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );
  const memoizedValue = useMemo(
    () => ({
      experience: (data?.data as SparkWebLocationExperienceDetailsDto) || {},
      experienceLoading: isLoading,
      experienceError: error,
      experienceValidating: isValidating,
      mutate,
    }),
    [data?.data, error, isLoading, isValidating, mutate],
  );

  return memoizedValue;
}

export function useGetExperienceSchedule(experienceId: number, date: string) {
  const URL = [
    endpoints.web.experiences.slots(experienceId),
    { params: { date } },
  ];

  const { data, isLoading, error, isValidating, mutate } = useSWR(
    URL,
    webFetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );
  const memoizedValue = useMemo(
    () => ({
      experienceSchedule: (data?.data as SparkWebExperienceScheduleDto) || {},
      experienceScheduleLoading: isLoading,
      experienceScheduleError: error,
      experienceScheduleValidating: isValidating,
      mutate,
    }),
    [data?.data, error, isLoading, isValidating, mutate],
  );

  return memoizedValue;
}
