import { IconButton } from '@mui/material';

import { Box } from '@mui/material';

import { Stack, Typography } from '@mui/material';
import { SparkTicketDto } from '../../../../../libs/contracts/src/lib/spark/tenant/v1/ticket/ticket.dto';
import { Iconify } from 'src/components/iconify';
import { useCheckoutContext } from '../checkout/context';
import { useMemo } from 'react';
import { SpacePricingEnum } from '../../../../../libs/space/src/lib/enums/space-pricing.enum';

type Props = {
  tickets: SparkTicketDto[];
};

export default function ExperienceTickets({ tickets }: Props) {
  const { space } = useCheckoutContext();
  const isNeedTicket = space?.pricingType === SpacePricingEnum.PER_PERSON;
  if (!isNeedTicket) return null;
  return (
    <Stack spacing={3} sx={{ mt: 4 }}>
      <Typography variant="h6">Select Tickets</Typography>
      <Stack spacing={2}>
        {tickets.map((ticket) => (
          <TicketItem key={ticket.id} ticket={ticket} />
        ))}
      </Stack>
    </Stack>
  );
}

function TicketItem({ ticket }: { ticket: SparkTicketDto }) {
  const { tickets, onIncreaseTicketQuantity, onDecreaseTicketQuantity } =
    useCheckoutContext();

  const quantity = useMemo(
    () => tickets.find((t) => t.ticketId === ticket.id)?.capacity || 0,
    [tickets, ticket.id],
  );

  const minTickets = 0;

  return (
    <Box
      key={ticket.id}
      sx={{
        p: 2,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack spacing={0.5}>
          <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
            {ticket.name}
          </Typography>
          <Typography variant="caption">{ticket.description}</Typography>
          <Typography variant="body2" color="text.secondary">
            {ticket.price} EGP
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            size="small"
            color="inherit"
            disabled={quantity === minTickets}
            onClick={() => onDecreaseTicketQuantity(ticket.id)}
          >
            <Iconify icon="eva:minus-fill" />
          </IconButton>

          <Typography
            variant="subtitle1"
            component="span"
            sx={{ minWidth: 40, textAlign: 'center' }}
          >
            {quantity}
          </Typography>

          <IconButton
            size="small"
            color="primary"
            onClick={() => onIncreaseTicketQuantity(ticket.id)}
          >
            <Iconify icon="eva:plus-fill" />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}
