// import { createPaletteChannel } from './styles';
import type { ThemeUpdateOptions } from './types';

// ----------------------------------------------------------------------

export const overridesTheme: ThemeUpdateOptions = {
  // colorSchemes: {
  //   light: {
  //     palette: {
  //       primary: createPaletteChannel({
  //         lighter: '#C8F2E8',
  //         light: '#5AD2BD',
  //         main: '#00BC92',
  //         dark: '#00806A',
  //         darker: '#00473F',
  //         contrastText: '#FFFFFF',
  //       }),
  //       secondary: createPaletteChannel({
  //         lighter: '#D0DFF0',
  //         light: '#799ECF',
  //         main: '#3E76BC',
  //         dark: '#2C5492',
  //         darker: '#193067',
  //         contrastText: '#FFFFFF',
  //       }),
  //     },
  //   },
  // },
};
